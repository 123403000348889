@font-face {
  font-family: "Antipasto";
  src: url("./assets/fonts/Antipasto-RegularTrial.ttf");
}

@font-face {
  font-family: "Antipasto-Light";
  src: url("./assets/fonts/Antipasto-ExtraLightTrial.ttf");
}

@font-face {
  font-family: "Antipasto-Bold";
  src: url("./assets/fonts/Antipasto-ExtraBoldTrial.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("./assets/fonts/OpenSans-ExtraBold.ttf");
}

strong {
  font-family: "OpenSans-ExtraBold";
}
